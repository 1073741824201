import { ContentType } from '@videoblocks/events-ts/lib/storyblocks/content/records/ContentTypeEnum';
import { ShallowStockItem } from '@videoblocks/events-ts/lib/storyblocks/content/records/ShallowStockItem';

export default class ShallowStockItemFactory {
  /**
   *
   * @param {object} stockItem - object with information about the stock item
   * @returns {ShallowStockItem}
   */
  static create(stockItem) {
    const shallowStockItem = new ShallowStockItem();
    shallowStockItem.stockItemId = stockItem.id;
    shallowStockItem.title = stockItem.title;
    shallowStockItem.distributionType =
      stockItem.distributionType === 'ML' ? 'MEMBER_LIBRARY' : 'REV_SHARE';
    shallowStockItem.contentType = ShallowStockItemFactory.contentType(
      stockItem.contentType
    );
    shallowStockItem.publishedDate = Date.parse(stockItem.dateAdded) || 0;
    shallowStockItem.format = null;
    shallowStockItem.thumbnailUrl = stockItem.thumbnailUrl;
    return shallowStockItem;
  }

  /**
   * @param contentType
   * @returns {ContentType}
   */
  static contentType(contentType) {
    const map = {
      sfx: ContentType.SOUND_EFFECTS,
      'sound-effects': ContentType.SOUND_EFFECTS,
      illustration: ContentType.ILLUSTRATIONS,
      photo: ContentType.PHOTOS,
      snapshot: ContentType.SNAPSHOTS,
      template: ContentType.TEMPLATES,
      motionbackground: ContentType.MOTION_BACKGROUNDS,
      'motion-backgrounds': ContentType.MOTION_BACKGROUNDS,
      vector: ContentType.VECTORS,
      vr360: ContentType.VR_360,
    };
    return (map[contentType?.toLowerCase()] || contentType).toUpperCase();
  }
}
