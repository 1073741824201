import { searchOrigins } from '../../Shared/constants';
import { SelectedSearchFilterOptions } from '../containers/MenuContainerInterfaces';

class AbstractSearchOptions<
  T extends Omit<SelectedSearchFilterOptions, 'buildSearchApiParameters'>,
> {
  constructor(data) {
    Object.assign(this, data);
  }

  update(newData: T): T {
    // Clone current object
    let clone = Object.assign(Object.create(this), this);

    // Force null values to be strings
    Object.keys(newData).map(
      (key) => (newData[key] = newData[key] === null ? '' : newData[key])
    );

    // Merge new data with existing data into a new object
    clone = Object.assign(clone, newData);

    return clone;
  }

  hash() {
    const str = JSON.stringify(this);

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const character = str.charCodeAt(i);
      hash = (hash << 5) - hash + character;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  static get commonDefaultData() {
    return {
      categories: '',
      searchTerm: '',
      sort: 'most_relevant',
      sortOrder: null,
      page: 1,
      loadMore: false,
      searchOrigin: searchOrigins.UNKNOWN,
      usageRights: null,
    };
  }

  buildSearchApiParameters() {
    throw new TypeError(
      this.constructor.name + ' must implement buildSearchApiParameters()'
    );
  }
}

export default AbstractSearchOptions;
