import { Category } from '../SearchTypes';

// Hack to decide whether to include snapshots in content types.
// Remove when releasing snapshots.
const includeSnapshots = Boolean(
  window.__INITIAL_STATE__?.auth?.featureFlags?.useSnapshotsUI
);

export const legacyContentTypes = [
  {
    label: 'All',
    value: 'all-images',
  },
  {
    label: 'Photos',
    value: 'photos',
  },
  includeSnapshots
    ? {
        label: 'Snapshots',
        value: 'snapshots',
        tooltip: 'Images exclusively sourced from our 4K footage library.',
      }
    : null,
  {
    label: 'Vectors',
    value: 'vectors',
  },
  {
    label: 'Illustrations',
    value: 'illustrations',
  },
].filter(Boolean);

export const fileTypes = [
  {
    label: 'Has Transparency',
    value: 'hasTransparency',
    name: 'hasTransparency',
  },
];

export const mediaDetails = [
  {
    label: 'Model Released',
    value: 'talentReleased',
    name: 'talentReleased',
  },
  {
    label: 'Property Released',
    value: 'propertyReleased',
    name: 'propertyReleased',
  },
];

export const orientations = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Landscape',
    value: 'landscape',
  },
  {
    label: 'Portrait',
    value: 'portrait',
  },
  {
    label: 'Square',
    value: 'square',
  },
];

export const categories = [
  {
    label: 'All',
    value: '',
    categoryId: null,
  },
  {
    label: 'Design Elements',
    value: '2',
    categoryId: 2,
    urlId: 'design-elements',
  },
  {
    label: 'Objects',
    value: '3',
    categoryId: 3,
    urlId: 'objects',
  },
  {
    label: 'Business',
    value: '4',
    categoryId: 4,
    urlId: 'business',
  },
  {
    label: 'People',
    value: '6',
    categoryId: 6,
    urlId: 'people',
  },
  {
    label: 'Holidays and Celebrations',
    value: '7',
    categoryId: 7,
    urlId: 'holidays-and-celebrations',
  },
  {
    label: 'Nature and Wildlife',
    value: '8',
    categoryId: 8,
    urlId: 'nature-and-wildlife',
  },
  {
    label: 'Buildings and Landmarks',
    value: '11',
    categoryId: 11,
    urlId: 'buildings-and-landmarks',
  },
  {
    label: 'Sports and Outdoors',
    value: '12',
    categoryId: 12,
    urlId: 'sports-and-outdoors',
  },
  {
    label: 'Fictional',
    value: '13',
    categoryId: 13,
    urlId: 'fictional',
  },
  {
    label: 'Travel and Transportation',
    value: '14',
    categoryId: 14,
    urlId: 'travel-and-transportation',
  },
  {
    label: 'Health and Science',
    value: '15',
    categoryId: 15,
    urlId: 'health-and-science',
  },
  {
    label: 'Backgrounds',
    value: '16',
    categoryId: 16,
    urlId: 'backgrounds',
  },
  {
    label: 'Cultural',
    value: '17',
    categoryId: 17,
    urlId: 'cultural',
  },
  {
    label: 'Beauty and Fashion',
    value: '18',
    categoryId: 18,
    urlId: 'beauty-and-fashion',
  },
].flatMap((category) =>
  legacyContentTypes.map(({ value }) => ({
    ...category,
    contentType: value,
  }))
);

export function getCategoriesForContentType(contentType): Category[] {
  return categories.filter((category: Category) => {
    return category.contentType === contentType;
  });
}
