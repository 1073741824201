import { ContentClass } from '../../Shared/enums';
import { AudioSelectedSearchFilterOptions } from '../containers/MenuContainerInterfaces';
import AbstractSearchOptions from './AbstractSearchOptions';
import { ALL_AUDIO_CONTENT_TYPE } from './SearchFilterOptions';

class AudioSearchOptions extends AbstractSearchOptions<AudioSelectedSearchFilterOptions> {
  constructor() {
    super({
      ...AbstractSearchOptions.commonDefaultData,
      contentClass: ContentClass.Audio,
      contentType: 'all-audio',
      sfxCategories: [],
      musicMoods: [],
      musicGenres: [],
      musicInstruments: [],
      vocalType: '',
      tempoMin: undefined,
      tempoMax: undefined,
      minDuration: null,
      maxDuration: null,
      artists: [],
      similarTo: undefined,
      searchSimilarTitle: undefined,
    });
  }

  /**
   * Accepts an optional reference object to map to allow static use
   */
  buildSearchApiParameters(searchParams = null) {
    const referenceObject = searchParams || this;

    return {
      ...this.buildSubCategoryApiParameters(referenceObject),
      searchTerm: referenceObject.searchTerm,
      'media-type': referenceObject.contentType,
      'search-origin': referenceObject.searchOrigin,
      duration_min: referenceObject.minDuration,
      duration_max: referenceObject.maxDuration,
      sort: referenceObject.sort,
      sortOrder: referenceObject.sortOrder,
      page: referenceObject.page,
      results_per_page: referenceObject.resultsPerPage,
      artist_ids:
        referenceObject?.artists?.map((artist) => artist.id).join('_') || '',
      'collapsed-set-id': referenceObject.collapsedSetId,
      'exclude-stock-item-ids': referenceObject.excludeStockItemIds,
      portal_artist_ids:
        referenceObject?.artists
          ?.map((artist) => artist.contributorPortalId)
          .join(',') || '',
      search_similar_id: referenceObject.similarTo,
    };
  }

  buildSubCategoryApiParameters(referenceObject) {
    switch (referenceObject?.contentType) {
      case 'music':
        return {
          moods: referenceObject?.musicMoods?.join('_') || '',
          genres: referenceObject?.musicGenres?.join('_') || '',
          instruments: referenceObject?.musicInstruments?.join('_') || '',
          bpm_min: referenceObject.tempoMin,
          bpm_max: referenceObject.tempoMax,
          has_vocals: referenceObject.vocalType,
        };
      case 'sfx':
        return {
          categories: referenceObject?.sfxCategories?.join('_') || '',
        };
      case ALL_AUDIO_CONTENT_TYPE:
      default:
        return {};
    }
  }
}

export default AudioSearchOptions;
