import { ContentClass } from '../../Shared/enums';
import { VideoSelectedSearchFilterOptions } from '../containers/MenuContainerInterfaces';
import AbstractSearchOptions from './AbstractSearchOptions';

class VideoSearchOptions extends AbstractSearchOptions<VideoSelectedSearchFilterOptions> {
  constructor() {
    super({
      ...AbstractSearchOptions.commonDefaultData,
      contentClass: ContentClass.Video,
      contentType: 'all-videos',
      templateType: '',
      videoQuality: 'HD',
      talentReleased: false,
      propertyReleased: false,
      minDuration: null,
      maxDuration: null,
      frameRates: [],
    });
  }

  buildSearchApiParameters(searchParams = null) {
    const referenceObject = searchParams || this;

    return {
      categories: referenceObject.categories,
      templateType: referenceObject.templateType,
      'media-type': referenceObject.contentType,
      searchTerm: referenceObject.searchTerm,
      video_quality: referenceObject.videoQuality,
      sort: referenceObject.sort,
      page: referenceObject.page,
      results_per_page: referenceObject.resultsPerPage,
      num_of_pages: referenceObject.numberOfPages,
      'load-more': referenceObject.loadMore,
      'search-origin': referenceObject.searchOrigin,
      min_duration: referenceObject.minDuration,
      max_duration: referenceObject.maxDuration,
      has_talent_released: referenceObject.talentReleased,
      has_property_released: referenceObject.propertyReleased,
      'collapsed-set-id': referenceObject.collapsedSetId,
      'exclude-stock-item-ids': referenceObject.excludeStockItemIds,
      frame_rates: referenceObject.frameRates,
      min_quality_score: referenceObject.minQualityScore,
      content_suggestions: JSON.stringify(referenceObject.contentSuggestions),
      usage_rights: referenceObject.usageRights,
    };
  }
}

export default VideoSearchOptions;
